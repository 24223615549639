import React from 'react';

import { useContextDetails } from 'src/hooks/useContextDetails';
import { i18n } from 'src/languages';
import * as Icons from 'src/assets/Icons';
import { TranslationValueType } from 'src/context/TranslationContext';

interface Props {
  message: string,
  type: string,
  ptype?: string,
  description?: TranslationValueType,
  hash?: string,
  brand: string,
  meta?: string;
}

export enum ErrorMessages {
  PaymentSuccess = 'payment_success',
  ChargeSuccess = 'charge_success',
  ChargeInProgress = 'charge_in_progress',
  PaymentInProgress = 'payment_in_progress',
  PaymentRejected = 'payment_rejected',
  PaymentExpired = 'payment_expired',
  PaymentFailure = 'payment_failure',
  ChargeFailure = 'charge_failure',
  PaymentCancelled = 'payment_cancelled',
  PaymentError = 'payment_error',
  ApmInProgress = 'apm_in_progress',
  ApmSpinner = 'apm_spinner',
}
export enum PaymentTypes {
  Payment = 'payment',
  Invoice = 'invoice',
  Charge = 'charge',
}

export const FALLBACK_TITLE = 'Loading...';

export const SUCCESS_TYPES: ErrorMessages[] = [
  ErrorMessages.PaymentSuccess,
  ErrorMessages.ChargeSuccess,
  ErrorMessages.ChargeInProgress,
];

export const ERROR_TYPES: ErrorMessages[] = [
  ErrorMessages.PaymentFailure,
  ErrorMessages.ChargeFailure,
];

export const IN_PROGRESS_TYPES: ErrorMessages[] = [
  ErrorMessages.ChargeInProgress,
  ErrorMessages.PaymentInProgress,
];

export const StatusContainer: React.FC<Props> = ({
  message,
  type,
  description,
  brand,
  meta,
}) => {
  const { t } = useContextDetails();

  const statusPageTitle = () => {
    if (SUCCESS_TYPES.includes(type as ErrorMessages)) {
      return t.TitleSuccess;
    } else if (type === ErrorMessages.PaymentRejected) {
      return t.TitleRejected;
    } else if (type === ErrorMessages.PaymentExpired) {
      return t.TitleExpired;
    } else if (type === ErrorMessages.PaymentFailure) {
      return t.TitleFailure;
    } else if (type === ErrorMessages.PaymentInProgress) {
      return t.TitleInProgress;
    } else if (type === ErrorMessages.ApmInProgress) {
      return t.TitleInProgress;
    } else if (type === ErrorMessages.ApmSpinner) {
      return !!meta ? `${meta} - ${i18n(t.TitleInProgress)}` : t.TitleInProgress;
    } else if (type === ErrorMessages.PaymentCancelled) {
      return i18n(t.TitleCancelled, { brand });
    } else if (type === ErrorMessages.PaymentError) {
      return t.TitlePaymentError;
    } else {
      return t.TitleFailure;
    }
  };

  document.title = i18n(t.TitleSuccess ? statusPageTitle() : FALLBACK_TITLE);

  return (
    <div className="formStatus" data-testid="formStatus">
      {SUCCESS_TYPES.includes(type as ErrorMessages) &&
        (<Icons.Success />)
      }
      {type === ErrorMessages.PaymentRejected &&
        (<Icons.Rejected />)
      }
      {type === ErrorMessages.PaymentExpired &&
        (<Icons.Error />)
      }
      {ERROR_TYPES.includes(type as ErrorMessages) &&
        (<Icons.Error />)
      }
      {type === ErrorMessages.PaymentCancelled &&
        (<Icons.Cancelled />)
      }
      {type === ErrorMessages.PaymentInProgress &&
        (<Icons.Cancelled />)
      }
      {type === ErrorMessages.ApmInProgress &&
        (<Icons.Cancelled />)
      }
      {type === ErrorMessages.ApmSpinner &&
        (
          <>
            <Icons.Progress />
            {meta && (<span>{meta}</span>)}
          </>
        )
      }
      {type === ErrorMessages.PaymentError &&
        (<Icons.Cancelled />)
      }
      <div>
        {message && (<p data-testid="statusMessage">{message}</p>)}

        {description && Array.isArray(description) && (
          <>
            {description.map((item, index) => (
              <span
                className="multiline"
                key={`${index}-descr`}
                data-testid={`statusDescription-${index}`}
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: item }}
              />
            ))}
          </>
        )}

        {description && !Array.isArray(description) && (
          <span
            data-testid="statusDescription"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: description }}
          />
        )}
      </div>
    </div>
  );
};
