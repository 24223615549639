/* eslint-disable react-hooks/exhaustive-deps */
import {
  useEffect,
  useState,
  useRef,
  useContext,
} from 'react';
import { useNavigate } from 'react-router-dom';

import { backend } from 'src/backend';
import { isDemoPayment } from 'src/demo/utils';
import { useDemoPayment } from 'src/hooks/useDemoPayment';
import { useError } from 'src/hooks/useError';
import { MissingFields } from 'src/hooks/useRequiredFields';
import { FieldsContext } from 'src/context/FieldsContext';
import { ThreeDsContext } from 'src/context/ThreeDsContext';
import { log } from 'src/loggers/sentry';
import { sdk } from 'src/sdk';
import { isIframe } from 'src/utils/iframe';
import { PaymentTypes } from 'src/components/containers/StatusContainer';
import { setTranslation } from 'src/components/payment/LanguageSwitcher';
import { TranslationContext } from 'src/context/TranslationContext';
import { useOnce } from './useOnce';
import { RequestOrigin } from 'src/backend/client';
import { isValidUUID } from 'src/utils/validators';
import { BackendError } from 'src/backend/error';
import { language, SUPPORTED_LANGUAGES } from 'src/languages/language';

export interface SavedCard {
  type?: string;
  digits?: string;
}

export enum FormType {
  FULLPAGE = 'fullpage',
  IFRAME = 'iframe'
}

export type AcceptedPaymentMethodsType = { [key: string]: PaymentMethods };

export interface Payment {
  accepted_cards: string[];
  accepted_payment_methods: AcceptedPaymentMethodsType;
  pay_form_config_url: string;
  form_type: FormType;
  amount: string;
  force_tokenization: boolean;
  is_oct: boolean;
  is_moto: boolean;
  is_apm: boolean;
  cvv_optional: boolean,
  show_cvc_input: boolean,
  original_client_id?: string;
  recurring?: boolean;
  brand_name: string;
  issued_on: string;
  lang_code: string;
  message?: string;
  redirect_url?: string;
  check_3ds?: boolean;
  is_volt_allowed: boolean;
  init_volt_url: string;
  is_klarna_allowed: boolean;
  init_klarna_url: string;
  init_open_banking_url: string;
  is_paypal_allowed: boolean;
  init_paypal_url: string;
  do_paypal_url: string;
  paypal_client_id: string;
  hash: string;
  currency: string;
  force_save_method?: boolean;
  saved_card: SavedCard;
  success_redirect: string;
  failure_redirect: string;
  payment_hash: string;
  cancel_redirect: boolean | string;
  paypal_cancel_redirect_url?: string;
  cancel_paypal_url?: string;
  immediate_capture: boolean;
  pan_first?: boolean;
  show_gateway_info?: boolean;
}

export interface PaymentProps {
  paymentId: string;
}

export interface PaymentResponse {
  payment: Payment;
  loading: boolean;
  error: string;
}

export const paymentInitialState = {
  pay_form_config_url: '',
  accepted_cards: [],
  accepted_payment_methods: {},
  form_type: FormType.FULLPAGE,
  amount: '',
  force_tokenization: false,
  is_oct: false,
  is_moto: false,
  is_apm: false,
  cvv_optional: false,
  show_cvc_input: true,
  original_client_id: '',
  recurring: false,
  brand_name: '',
  issued_on: '',
  lang_code: '',
  message: '',
  pan_first: false,
  redirect_url: '',
  hash: '',
  is_volt_allowed: false,
  init_volt_url: '',
  init_open_banking_url: '',
  is_klarna_allowed: false,
  init_klarna_url: '',
  is_paypal_allowed: false,
  init_paypal_url: '',
  do_paypal_url: '',
  paypal_client_id: '',
  saved_card: {
    type: '',
    digits: '',
  },
  currency: '',
  force_save_method: false,
  success_redirect: '',
  failure_redirect: '',
  cancel_redirect: '',
  payment_hash: '',
  immediate_capture: false,
  show_gateway_info: true,
};

export enum PaymentMethods {
  CARD = 'card',
  PAYPAL = 'paypal',
  VOLT = 'volt',
  OPENBANKING_TRX1 = 'openbanking',
  OPENBANKING_TRX2 = 'open_banking',
  KLARNA = 'klarna',
}

export const isPaymentMethodVisible = (details: Payment, method: string, activeMethod: string): boolean => {
  const acceptedPaymentMethods = Object.values(details.accepted_payment_methods);
  const includesPayment = acceptedPaymentMethods.includes(method as PaymentMethods);
  const isActiveMethod = method === activeMethod;
  const isSDK = isIframe && sdk.hasOrigin && isActiveMethod;

  if (!includesPayment) {
    return false;
  }

  switch (method) {
    case PaymentMethods.CARD:
      return !true || isActiveMethod || isSDK || isDemoPayment(details.hash) || (isIframe && !sdk.hasOrigin);
    case PaymentMethods.PAYPAL:
      return includesPayment;
    case PaymentMethods.VOLT:
    case PaymentMethods.KLARNA:
      return (!true && !isIframe) || (isActiveMethod && !isIframe) || isSDK;
    case PaymentMethods.OPENBANKING_TRX1:
    case PaymentMethods.OPENBANKING_TRX2:
      return (isActiveMethod && !isIframe) || isSDK;
  }
  return false;
};

export const isOnlyMethod = (details: Payment, method: string) => {
  const isCardPaymentVisible = isPaymentMethodVisible(details, PaymentMethods.CARD, PaymentMethods.PAYPAL);
  if (isIframe && !isCardPaymentVisible && !sdk.hasOrigin) {
    return true;
  }
  const acceptedPaymentMethods = Object.values(details.accepted_payment_methods);
  return (acceptedPaymentMethods.length === 1 && acceptedPaymentMethods.includes(method as PaymentMethods));
};

const logPayment = (details: Payment) => {
  log('formType', details.form_type);
};

export const onSuccessRedirect = (
  check_3ds: boolean,
  url: string,
  callBack?: (url: string) => unknown,
) => {
  if (!check_3ds) {
    window.location.href = url;
    return;
  }

  if (isIframe) {
    sdk.addActionListener((actionId?: string, actionUrl?: string) => {
      if (actionUrl) {
        window.location.href = actionUrl;
      }
    });
    sdk.ds3Url = url;
    return;
  }

  if (callBack) {
    callBack(url);
  }
};

export const getRequestOrigin = () => {
  if (isIframe) {
    if (sdk.hasOrigin) {
      return RequestOrigin.SDK;
    }

    return RequestOrigin.IFRAME;
  }

  return RequestOrigin.FULLPAGE;
};

const ERROR_NOT_FOUND = 'Payment not found';

export const usePayment = ({
  paymentId,
}: PaymentProps): PaymentResponse => {
  const isDemoMode = isDemoPayment(paymentId);
  const demoResponse = useDemoPayment();
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);
  const [fieldsLoading, setFieldsLoading] = useState<boolean>(false);
  const [payment, setPayment] = useState<Payment>(paymentInitialState);
  const { logError } = useError();

  const [error, setError] = useState<string>('');
  const shouldFetchMissingFields = useRef(true);


  const fieldsContext = useContext(FieldsContext);
  const translationContext = useContext(TranslationContext);
  const threeDsContext = useContext(ThreeDsContext);

  const onSuccess = (response: Payment) => {
    if (response.message) {
      setError(response.message);
    } else if (response.redirect_url) {
      onSuccessRedirect(
        !!response.check_3ds,
        response.redirect_url,
        (data: string) => threeDsContext.setUrl(data),
      );
    } else {
      const lang = language.getLanguage(response.lang_code as SUPPORTED_LANGUAGES);
      setTranslation(lang, translationContext);
      language.toStorage = lang;
      setPayment(response);
      logPayment(response);
    }
    setLoading(false);
  };

  const onFail = (fetchError: Error) => {
    setError(ERROR_NOT_FOUND);
    logError(fetchError);
    setLoading(false);
  };

  const onChargeSuccess = (response: MissingFields) => {
    setFieldsLoading(false);
    if (response.missing_fields.length) {
      fieldsContext.setFields(response);
      navigate(`/client/charge/${payment.payment_hash}/payment/details_request/`);
    }
  };

  useEffect(() => {
    if (payment.payment_hash && payment.is_oct) {
      if (shouldFetchMissingFields.current) {
        shouldFetchMissingFields.current = false;
        setFieldsLoading(true);
        backend.requiredFields<MissingFields>({
          pid: payment.payment_hash,
          ptype: PaymentTypes.Charge,
          onSuccess: onChargeSuccess,
          onFail: () => { setFieldsLoading(false); },
        });
      }
    }
  }, [payment.payment_hash]);

  useOnce(() => {
    setLoading(true);
    const hasValidId = isValidUUID(paymentId);
    const shouldQueryApi = !isDemoMode && !payment.hash.length && hasValidId;
    if (shouldQueryApi) {
      backend.getPayFormConfig<Payment>({
        pid: paymentId,
        apiProps: {
          origin: getRequestOrigin(),
          language: language.detected,
        },
        onSuccess,
        onFail,
      });
    }
    if (!hasValidId) {
      setError(ERROR_NOT_FOUND);
      logError(new BackendError(ERROR_NOT_FOUND, 404));
      setLoading(false);
    }
  });

  return isDemoMode
    ? demoResponse
    : {
      payment,
      loading: loading || fieldsLoading,
      error,
    };
};
