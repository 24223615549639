export const statusConfigInitialState = () => ({
  'config': {
    'id': '',
    'email': '',
    'number': '',
    'payform_type': 0,
    'paid_on': 0,
    'issued_on': 0,
    'due': 0,
    'get_company_name': '',
    'view_payment_button_display_needed': false,
    'totals': {
      'subtotal': '',
      'vat': '',
      'discount': '',
      'total': '',
      'refunds_total': '',
      'withdraw_total': '',
    },
    'language': '',
    'currency': '',
    'download_preview_url': '',
    'reject_invoice_url': '',
    'support_phone': '',
    'full_legal_name': '',
    'full_legal_address': '',
    'registration_nr': '',
    'vat_payer_nr': '',
    'swift': '',
    'iban': '',
    'products': [
      {
        'currency': '',
        'description': '',
        'discount_amount': '',
        'discount_percent': '',
        'id': '',
        'image': '',
        'price': '',
        'quantity': '',
        'tax': {
          'id': '',
          'name': '',
          'percent': '',
          'type': '',
        },
        'tax_amount': '',
        'tax_percent': '',
        'title': '',
        'total': '',
        'type': '',
      },
    ],
    'transaction_id': '',
  },
  'in_progress_redirect': null,
  'success_redirect': null,
  'failure_redirect': null,
  'cancel_redirect': null,
  'url': '',
  'just_paid_this_invoice': false,
  'status_page_name': '',
  'payment_execution_method': null,
});
